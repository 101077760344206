<template>
  <section>
    <v-container>
      <v-row class="mb-4">
        <v-col align="right">
          <v-btn class="primary" @click="doAddTeamMember">
            Add Team Member
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table :loading="isLoading" :headers="headers" :items="teamMembers" class="elevation-1" @click:row="doOpenRow($event)">
        <template v-slot:item.isPublic="{ item }">
          <v-switch
            class="mt-0"
            color="success"
            v-model="item.isPublic"
            hide-details
            @click="doToggleActivation($event, item.id)"
          ></v-switch>
        </template>
      </v-data-table>
    </v-container>
    <add-team-member ref="addTeamMember" @doLoad="doLoad"></add-team-member>
    <view-team-member ref="viewTeamMember" @doLoad="doLoad"></view-team-member>
    <toggle-public ref="togglePublic" @doLoad="doLoad"></toggle-public>
  </section>
</template>

<script>
import AddTeamMember from '@/views/admin/team/addTeamMember.vue'
import TogglePublic from '@/views/admin/team/togglePublic.vue'
import ViewTeamMember from '@/views/admin/team/viewTeamMember.vue'

export default {
  name: 'Team',
  components: { ViewTeamMember, TogglePublic, AddTeamMember },
  data: () => ({
    isLoading: false,
    headers: [
      {
        text: 'Team Member',
        align: 'start',
        sortable: true,
        value: 'fullName'
      },
      {
        text: 'Title',
        value: 'title'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Public',
        value: 'isPublic'
      }
    ],
    teamMembers: []
  }),
  methods: {
    doLoad () {
      this.isLoading = true

      this.$services.teamMember.doGetAll().then(teamMembers => {
        this.teamMembers = teamMembers
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doAddTeamMember () {
      this.$refs.addTeamMember.doOpen()
    },
    doOpenRow (row) {
      this.$refs.viewTeamMember.doOpen(row.id)
    },
    doToggleActivation (event, id) {
      event.stopPropagation()

      this.$refs.togglePublic.doOpen(id)
    }
  },
  mounted () {
    this.doLoad()
  }
}
</script>

<style scoped>

</style>
