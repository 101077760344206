<template>
  <span>
    <v-overlay :value="isLoading" z-index="500">
      <div class="text-center pa-8 rounded-lg" style="width: 200px;">
         <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
    <v-card>
      <v-card-title class="text-h5">
        <span>
          You sure you want to <span class="text-decoration-underline"> {{ teamMember.isPublic ? 'Hide' : 'Show' }}</span>
        </span>
        <div>
          <div class="text-center ma-4">
            <cropped-image-viewer class="centered" :image="teamMember.image.url" :coordinates="teamMember.image.coordinates" :width="100" :height="100"></cropped-image-viewer>
          </div>
          <span class="ml-2">
            {{ teamMember.fullName }} ?
          </span>
        </div>
      </v-card-title>
      <v-card-text>{{ teamMember.isPublic ? 'Hiding this team member will keep them in the system, however hide them from the website publicly.' : 'Showing this team member will make them public on the website!' }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="doClose"
        >
          Cancel
        </v-btn>
        <v-btn
          :color="teamMember.isPublic ? 'red' : 'success'"
          text
          @click="doToggleActivation"
        >
          {{ teamMember.isPublic ? 'Hide Publicly' : 'Show Publicly' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
import CroppedImageViewer from '@/components/image/croppedImageViewer.vue'

export default {
  name: 'togglePublic',
  components: { CroppedImageViewer },
  data: () => ({
    isLoading: false,
    isOpen: false,
    teamMember: {
      firstName: null,
      lastName: null,
      fullName: null,
      email: null,
      title: null,
      about: null,
      image: {
        url: null,
        coordinates: null
      },
      isPublic: false
    }
  }),
  methods: {
    doOpen (id) {
      this.teamMember.id = id
      this.isLoading = true

      this.$services.teamMember.doRead(id).then(teamMember => {
        this.teamMember = teamMember

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doToggleActivation () {
      this.isOpen = false

      this.isLoading = true

      if (this.teamMember.isPublic) {
        this.$services.teamMember.doMakePrivate(this.teamMember.id).then().catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
          this.doClose()
        })
      } else {
        this.$services.teamMember.doMakePublic(this.teamMember.id).then().catch(error => {
          this.$root.$emit('showError', error.message)
        }).finally(_ => {
          this.isLoading = false
          this.doClose()
        })
      }
    },
    doClose () {
      this.$emit('doLoad')
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
