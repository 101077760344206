<template>
  <span>
    <v-overlay :value="isLoading" z-index="500">
      <div class="text-center pa-8 rounded-lg" style="width: 200px;">
         <v-progress-circular indeterminate color="white"></v-progress-circular>
      </div>
    </v-overlay>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
    <v-card>
      <v-card-title class="text-h5">
        <span>
          You sure you want to <span class="text-decoration-underline red--text"> Delete</span>
        </span>
        <span>
        <span class="ml-2">
          {{ teamMember.fullName }} ?
        </span>
        </span>
      </v-card-title>
      <v-card-text>Although deleting a team member should be self-explanatory, let's provide some clarification. Doing so will result in the complete removal of all information associated with this team member from Sea2Sea Scuba. It is important to carefully consider this action before proceeding.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="doClose"
        >
          Cancel
        </v-btn>
        <v-btn
          color="red"
          text
          @click="doDestroy"
        >
          Destroy
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'deleteTeamMember',
  data: () => ({
    isLoading: false,
    isOpen: false,
    teamMember: {
      id: null,
      fullName: null,
      image: null
    }
  }),
  methods: {
    doOpen (id) {
      this.isLoading = true

      this.$services.teamMember.doRead(id).then(teamMember => {
        this.teamMember = teamMember

        this.isOpen = true
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doDestroy () {
      this.isOpen = false

      this.isLoading = true
      this.$services.teamMember.doDestroy(this.teamMember.id).then().catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.$emit('didDestroy')
        this.isLoading = false
      })
    },
    doClose () {
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>
